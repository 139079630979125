<template>
  <div class="match-all">
    <banner-bg :type="8">
      <div class="center match-banner">
        <div class="banner">
          <div class="banner-btn">
            <el-dropdown v-if="isLogin">
              <span class="el-dropdown-link">
                <el-avatar
                  style="width: 30px; height: 30px; margin-right: 10px"
                  :src="userInfo.avatar"
                ></el-avatar>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    type="text"
                    @click="logoutConfirm"
                  >退出</el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <template v-else>
              <el-button
                @click="toLogin()"
                class="my-btn"
              >登录</el-button>
              <el-button
                @click="toRegister()"
                class="my-btn"
              >注册</el-button>
            </template>
          </div>
          <div
            style="height: 300px"
            v-if="bannerList.length == 1"
          >
            <el-image
              style="height: 300px; width: 1000px"
              fit="fill"
              :src="bannerList[0].picUrl"
            ></el-image>
          </div>
          <el-carousel
            v-else
            height="300px"
          >
            <el-carousel-item
              v-for="(item, key) in bannerList"
              :key="key"
            >
              <a
                :href="item.href"
                target="_blank"
              >
                <el-image
                  style="height: 300px; width: 1000px"
                  fit="fill"
                  :src="item.picUrl"
                ></el-image>
              </a>
            </el-carousel-item>
          </el-carousel>
          <!-- <div class="banner-text">
          <div>参加编程赛事，锻炼实践能力</div>
          <br />
          <p>参加编程赛事，锻炼实践能力</p>
          <p>获得优异名次，提高自身竞争力</p>
        </div> -->
        </div>
      </div>
    </banner-bg>
    <div class="match-list">
      <div class="list-filter">
        <div class="filter-search">
          <p>赛事列表</p>
          <!-- <el-input
            v-model="name"
            placeholder="请输入名称"
            clearable
            style="width:300px"
          ><i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input> -->
        </div>
        <div class="filter-select">
          <!-- <div class="select-item" v-for="item in filter" :key="item.key">
            <span>{{item.label}}</span>
            <el-select v-model="filterValues[item.value]" clearable placeholder="请选择">
              <el-option
                v-for="item in options[item.key]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="select-item">
            <span>赛事系列</span>
            <el-select
              v-model="seriesValue"
              placeholder="请选择"
              @change="changeFilterSeries"
              style="width:200px"
              clearable
            >
              <el-option
                v-for="item in seriesOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span>项目类别</span>
            <el-select
              v-model="typeValue"
              clearable
              placeholder="请选择"
              @change="changeFilter"
              style="width:200px"
            >
              <el-option
                v-for="item in typeOptions[seriesValue]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="list-items">
        <el-card
          class="list-item"
          v-for="item in currentPageMatchList"
          :key="item.id"
        >
          <!-- <div> -->
          <img
            class="item-bg"
            src="~@/assets/image/match/match-bg.png"
            v-real-img="item.cover"
          />
          <!-- </div> -->
          <div class="item-div">
            <div class="item-left">
              <!-- <img style="width: 456px; height: 267px" /> -->
            </div>
            <div class="item-right">
              <div class="flex item-status">
                <div
                  class="center status-text"
                  :class="
                    item.processText == '未开始' || item.processText == '已结束'
                      ? 'status-unStart'
                      : ''
                  "
                >
                  {{ item.processText }}
                </div>
              </div>
              <div
                class="item-title"
                @click="gotoMatchDetail(item.id)"
                style="-webkit-box-orient: vertical"
                title=""
              >
                <span class="text-oveflow">{{ item.matchSeriesName }}</span><br />
                <span class="text-oveflow">{{ item.title }}({{ item.matchProjectName }})</span>
              </div>
              <div class="item-info">
                <div class="flex info-unit">
                  <div>主办单位：</div>
                  <div>
                    <span class="text-oveflow">{{
                      item.organizers.length ? item.organizers[0] : ''
                    }}</span><br />
                    <span class="text-oveflow">{{
                      item.organizers.length > 1 ? item.organizers[1] : ''
                    }}</span>
                  </div>
                </div>
                <div class="flex info-time">
                  <div>活动时间：</div>
                  <!-- <div>{{ item.start_time }} --- {{ item.end_time }}</div> -->
                  <div>
                    {{ $moment(item.startTime).format('YYYY-MM-DD') }} ---
                    {{ $moment(item.endTime).format('YYYY-MM-DD') }}
                  </div>
                </div>
              </div>
              <div
                class="item-detail"
                @click="gotoMatchDetail(item.id)"
              >
                查看活动详情
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div
        v-if="
          currentPage === Math.ceil(matchList.length / pageSize) ||
          !matchList.length
        "
        class="list-others"
      >
        更多赛事准备中，敬请期待(●'◡'●)
      </div>
      <div class="m-pagin">
        <el-pagination
          v-if="matchList.length"
          background
          :page-size="pageSize"
          :total="matchList.length"
          :current-page.sync="currentPage"
          layout="total , prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import BannerBg from '@/components/BannerBg.vue';

import { getBanners } from '@/api/common';
import { getUserInfo } from '@/utils/auth';

import { getMatchList, getMatchSeries } from '@/api/match';
export default {
  components: { BannerBg },
  data() {
    return {
      bannerList: [],
      name: '',
      value: '',
      pageNo: 1,
      pageSize: 10,
      seriesOptions: [],
      typeOptions: {},
      seriesValue: '',
      typeValue: '',
      // options:{series:[],type:[],name:[]},
      // filterValues:{series:'',type:'',name:''},
      // filter:[
      //   {label:'赛事系列',key:'series'},
      //   {label:'项目类别',key:'type'},
      //   // {label:'项目名称',key:'name'},
      // ],
      statusText: { 0: '未开始', 1: '进行中', 2: '已结束', 3: '延期中' },
      currentPage: 1,
    };
  },
  created: function () {
    this.getBannerList();
    let that = this;
    //1. 发送请求
    getMatchSeries({}).then((res) => {
      const { success, result = [] } = res;
      if (!success) {
        return;
      }
      // let seriesOptions = [{ label: '全部', value: '' }];
      let seriesOptions = [];
      let typeOptions = {};
      let seriesValue = '';
      result.map((item, index) => {
        // if (index === 0) {
        //   seriesValue = item.id;
        // }
        seriesOptions.push({ label: item.name, value: item.id });
        // let options = [{ label: '全部', value: '' }];
        let options = [];
        const { matchProjects } = item;
        matchProjects.map((project) => {
          options.push({ label: project.name, value: project.id });
        });
        typeOptions[item.id] = [...options];
      });
      this.seriesOptions = seriesOptions;
      this.typeOptions = typeOptions;
      this.seriesValue = seriesValue;
      const params = {}; //{projectId:seriesValue};
      //2.获取赛事列表数据
      this.getMatchList(params);
    });
  },
  computed: {
    matchList() {
      return this.$store.state.matchList;
    },
    // 筛选总数据设置当前页需要展示的列表结果
    currentPageMatchList() {
      return this.matchList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize,
      );
    },
    isLogin: function () {
      let userInfo = getUserInfo();
      return !!userInfo;
    },
    userInfo: function () {
      return getUserInfo();
    },
  },
  methods: {
    logoutConfirm: function () {
      let that = this;
      MessageBox({
        type: 'warning',
        title: '系统提示',
        message: '您确定要退出登录吗',
      }).then(() => {
        that.$store.dispatch('LogoutUser');
        window.location.reload();
      });
    },
    toLogin() {
      this.$router.push(`/login?redirect_uri=${location.href}`);
    },
    toRegister() {
      this.$router.push({
        path: '/login',
        query: {
          type: 'reg',
        },
      });
    },
    getBannerList() {
      let param = {
        available: 1,
        pageSize: 99,
        location: 'ss',
        platform: 'yqb',
        isPortal: '1'
      };
      getBanners(param).then((res) => {
        if (res.success) {
          this.bannerList = res.result.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    gotoMatchDetail(id) {
      let routeData = this.$router.resolve({
        path: `/match-player`,
        query: { id: id },
      });
      window.open(routeData.href, '_blank');
    },
    getMatchList(params) {
      getMatchList(params)
        .then((res) => {
          const { result } = res;
          const matchList = [];
          result.map((item) => {
            item.organizers = item.organizers.replace(/，/gi, ',');
            item.organizers = item.organizers.split(',');
            matchList.push(item);
          });
          this.$store.dispatch('SetMatchList', { matchList });
        })
        .catch((error) => {
          that.$notify({
            title: '获取赛事列表失败',
            message: error || '请联系管理员',
            type: 'warning',
          });
        });
    },
    changeFilter(value) {
      let params = value ? { projectId: value } : {};
      if (value == '') {
        //全部时传当前系列下的所有项目ID
        let projIdStr = '';
        this.typeOptions[this.seriesValue].map((item) => {
          if (item.value != '') {
            projIdStr += item.value + ',';
          }
        });
        params = { projectId: projIdStr.trim(',') };
      }
      this.getMatchList(params);
    },
    changeFilterSeries(value) {
      let params = {};
      //if(this.typeOptions[value]&&this.typeOptions[value].length===1){
      if (this.typeOptions[value]) {
        this.typeValue = this.typeOptions[value][0].value;
        // 当前选中系列下的全部项目
        let projIdStr = '';
        this.typeOptions[value].map((item) => {
          if (item.value != '') {
            projIdStr += item.value + ',';
          }
        });
        params = { projectId: projIdStr.trim(',') };
      }
      this.getMatchList(params);
    },
  },
};
</script>
<style scoped>
.el-card /deep/ .el-card__body {
  display: flex;
  width: 100%;
  padding: 0;
}
.m-pagin {
  text-align: right;
  width: 1000px;
  margin-bottom: 50px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/views/match.scss';
</style>
